

const Privileged = () => {
    return (
        <>
            <div style={{color:'white'}}>Priv</div>
        </>
    )
}

export default Privileged;